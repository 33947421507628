<template>
  <div class="code_box">
    <div class="login-top">
        <van-icon name="cross" size="25"/>
    </div>
    <div class="title">
        <div class="text1">输入验证码</div>
        <div class="text2">验证码已发送至 +86 157 0974</div>
    </div>
    <div class="password_yz">
        <van-password-input
            :value="value"
            :mask="false"
            :focused="showKeyboard"
            @focus="showKeyboard = true"
            />
    </div>
    <div class="code_tit">
        52秒后可重新获取
    </div>
    <!-- 数字键盘 -->
    <van-number-keyboard
    v-model="value"
    :show="showKeyboard"
    @blur="showKeyboard = false"
    />
  </div>
</template>

<script>
export default {
  name: "codeIndex",

  data() {
    return {
        showKeyboard: false,
        value: ''
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>

.code_box{
    width: 100vw;
    height: 100vh;
    background: #fff;
}
.login-top{
    display: flex;
    justify-content: space-between;
    padding: 15rem 17rem;
}
.title{
    padding: 30rem 32rem;
    .text1{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 24rem;
        color: #000000;
    }
    .text2{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14rem;
        color: #BEC2D1;
        margin-top: 2rem;
    }
}
.password_yz{
    margin-top: 30rem;
}
.code_tit{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14rem;
    color: #BEC2D1;
    margin: 14rem 0rem 0rem 14rem;
}
</style>
